import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/profile.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
      <a href="#" className="image avatar">
        <img src={avatar} alt="" />
      </a>
      <h1>Osledy Bazó - Full Stack Developer</h1>
    </div>
  </header>
)

export default Header
